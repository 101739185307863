<div class="dialog-wrap {{inputMode}}" #dialogWrap>
  <nb-card [nbSpinner]="loading" *ngFor="let data of this.data; let i=index;" style="max-height: initial; margin: 0 auto; width: 22cm;" class="small-header">
    <nb-card-header>
      <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="data['Title'] || ('Warehouse.ProductionOrder.title' | translate:{definition: '', action: cms.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
    </nb-card-header>
    <nb-card-body class="print-body" #printContent>
      <div id="print-area">
        <ngx-print-header></ngx-print-header>
        <div>
          <div class="print-title">{{ 'Warehouse.ProductionOrder.title' | translate:{action: '', definition: ''} |
            uppercase }}
          </div>
          <div class="under-title">{{ data.Code }} - {{ data.DateOfReceipted | date:'short' }}</div>
        </div>
        <div>
          <div style="display: flex;">
            <div style="flex: 1;">
              <div class="under-line">{{'Common.contact' | translate | headtitlecase}} :
                {{renderValue(data.ObjectName)}}
              </div>
            </div>
            <div>
              <div class="under-line">{{'Common.contactId' | translate | headtitlecase}} :
                {{renderValue(data.Object | objectid)}}
              </div>
            </div>
          </div>
          <div class="under-line">{{'Common.address' | translate | headtitlecase}} :
            {{renderValue(data.ObjectAddress)}}
          </div>
        </div>
        <div>
          <div>
            <table style="width: 100%;" class="print-voucher-detail-table">
              <thead class="print-voucher-detail-header">
                <td>#</td>
                <td class="nowrap" style="min-width: 10rem;">{{'Sku/ID' | translate | headtitlecase}}</td>
                <td class="nowrap">{{'Thành phẩm' | translate | headtitlecase}}</td>
                <td class="text-align-right nowrap">{{'CP NVL' | translate | headtitlecase}}</td>
                <td class="text-align-right nowrap">{{'CP Phân bổ' | translate | headtitlecase}}</td>
                <td class="text-align-right nowrap">{{'Giá thành' | translate | headtitlecase}}</td>
                <td class="text-align-right nowrap">{{'Số lượng' | translate | headtitlecase}}</td>
              </thead>
              <ng-container *ngFor="let finishedGoodsItem of data.FinishedGoods; let i = index;">
                <tr class="print-voucher-detail-line">
                  <td>{{finishedGoodsItem.No}}</td>
                  <td>
                    <div>{{finishedGoodsItem.FinishedGoods?.Sku}}/{{finishedGoodsItem.FinishedGoods | objectid}}</div>
                  </td>
                  <td class="text-align-left">
                    <div>{{finishedGoodsItem.FinishedGoods | objecttext}}</div>
                  </td>
                  <td class="text-align-right">
                    <div>{{finishedGoodsItem.MaterialCost | number:'1.4-4'}} ₫</div>
                  </td>
                  <td class="text-align-right">
                    <div>{{finishedGoodsItem.DistributedCost | number:'1.4-4'}} ₫</div>
                  </td>
                  <td class="text-align-right">
                    <div>{{finishedGoodsItem.CostOfProduction | number:'1.4-4'}} ₫</div>
                  </td>
                  <td class="text-align-right">
                    <div>{{finishedGoodsItem.Quantity | number}} {{finishedGoodsItem.Unit | objecttext}}</div>
                  </td>
                </tr>
                <tr class="print-voucher-detail-line">
                  <td></td>
                  <td colspan="6">
                    <table style="width: 100%;" class="print-voucher-detail-table">

                      <thead class="print-voucher-detail-header">
                        <td>#</td>
                        <td class="nowrap" style="min-width: 10rem;">{{'Sku/ID' | translate | headtitlecase}}</td>
                        <td class="nowrap">{{'Common.description' | translate | headtitlecase}}</td>
                        <td class="text-align-right nowrap">{{'NVL/CP' | translate | headtitlecase}}</td>
                        <td class="text-align-right nowrap">{{'Định mức' | translate | headtitlecase}}</td>
                        <td class="text-align-right nowrap">{{'SL Yêu cầu' | translate | headtitlecase}}</td>
                        <td class="text-align-right nowrap">{{'ĐVT' | translate | headtitlecase}}</td>
                      </thead>

                      <tr *ngFor="let material of finishedGoodsItem.Materials; let m = index;">
                        <td>{{material.No}}</td>
                        <td>
                          <div>{{material.Material?.Sku}}/{{material.Material | objectid}}</div>
                        </td>
                        <td class="text-align-left">
                          <div>{{material.Material | objecttext}}</div>
                        </td>
                        <td class="text-align-right">
                          <div>{{material.Cost | number:'1.4-4'}} ₫</div>
                        </td>
                        <td class="text-align-right">
                          <div>{{material.Budgested | number:'1.4-4'}} ₫</div>
                        </td>
                        <td class="text-align-right">
                          <div>{{material.Quantity | number:'1.4-4'}}</div>
                        </td>
                        <td class="text-align-right">
                          <div>{{material.Unit | objecttext}}</div>
                        </td>
                      </tr>
                      <tr *ngFor="let distributedCost of finishedGoodsItem.DistributedCosts; let m = index;">
                        <td>{{distributedCost.No}}</td>
                        <td>
                          <div>{{distributedCost.CostClassification | objectid}}</div>
                        </td>
                        <td class="text-align-left">
                          <div>{{distributedCost.CostClassification | objecttext}} ({{distributedCost.Account}})</div>
                        </td>
                        <td class="text-align-right">
                          <div>{{distributedCost.Cost/finishedGoodsItem.Quantity | number:'1.4-4'}} ₫</div>
                        </td>
                        <td class="text-align-right">
                          <div></div>
                        </td>
                        <td class="text-align-right">
                          <div></div>
                        </td>
                        <td class="text-align-right">
                          <div></div>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </ng-container>
              <tr>
                <td colspan="7">{{'Common.note' | translate | headtitlecase}}</td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <div class="under-line" *ngIf="data.Note"><span [innerHTML]="renderValue(data.Note)"></span></div>
          <!-- <div class="under-line">{{'Sales.deliveryAddress' | translate | headtitlecase}}:
            {{renderValue(data.DeliveryAddress)}}
          </div> -->
          <!-- <div class="under-line">{{'Sales.directReceiver' | translate | headtitlecase}}:
            {{renderValue(data.DirectReceiverName)}}
          </div> -->
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <div style="text-align: center; flex: 0 0 50%;">
            <br> {{'Quản lý' | translate | headtitlecase}}
            <br><br><br>
          </div>
          <div style="text-align: center; flex: 0 0 50%;">
            <br> {{'Giảm đốc' | translate | headtitlecase}}
            <br><br><br>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer *ngIf="data.RelativeVouchers && data.RelativeVouchers.length > 0" class="print-relative-vouchers">
      <span class="tag" *ngFor="let relationVoucher of data.RelativeVouchers" nbTooltip="{{relationVoucher.type?.text}}: {{relationVoucher | objectstext}}">
        <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.type?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
        <nb-icon pack="eva" icon="close-outline" class="close-btn"></nb-icon>
      </span>
    </nb-card-footer>
    <nb-card-footer>
      <div class="buttons-row">
        <button *ngIf="mode === 'print'" nbButton hero status="info" (click)="edit(data);">
          <nb-icon pack="eva" icon="edit"></nb-icon>{{'Common.edit' | translate | headtitlecase}}
        </button>
        <button nbButton hero status="info" (click)="prepareCopy(data);" style="float: left;">
          <nb-icon pack="eva" icon="copy"></nb-icon>{{'Common.copy' | translate | headtitlecase}}
        </button>
        <!-- <button nbButton hero status="danger" (click)="approvedConfirm(data, i);">{{(processMapList[i]?.nextStateLabel || '') | translate | headtitlecase}}<nb-icon pack="eva" icon="skip-forward"></nb-icon></button> -->

        <!--State process buttons-->
        <button *ngFor="let nextState of processMapList[i]?.nextStates" nbButton hero status="{{nextState.status}}" (click)="stateActionConfirm(data, nextState);">{{nextState.confirmLabel | translate | headtitlecase}}<nb-icon pack="{{nextState.iconPack || 'eva'}}" icon="{{nextState.icon || 'skip-forward'}}"></nb-icon></button>
        <!--End State process buttons-->

        <button *ngIf="!sourceOfDialog || sourceOfDialog === 'form'" nbButton hero status="success" (click)="saveAndClose(data);">
          <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>