<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="dialog-wrap popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Collaborator.Commission.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <div class="row">
                            <input type="hidden" formControlName="Code">
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="Publisher" label="Collaborator.Publisher.label" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <ngx-select2 formControlName="Publisher" [select2Option]="select2OptionForContact" (selectChange)="onObjectChange(formItem, $event, i)"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="PublisherName" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="PublisherName" placeholder="{{'Common.name' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="PublisherPhone" label="Common.phone" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="PublisherPhone" placeholder="{{'Common.phone' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="Page" label="Collaborator.Page.label" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [customIcons]="objectControlIcons">
                                    <ngx-select2 formControlName="Page" [select2Option]="select2OptionForPage" [data]="this.collaboratorService.pageList$.value"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-8">
                                <ngx-form-group [formGroup]="formItem" name="PublisherAddress" label="Common.address" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="PublisherAddress" placeholder="{{'Common.address' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="PublisherEmail" label="Common.email" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="PublisherEmail" placeholder="{{'Common.email' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="PublisherIdentifiedNumber" label="Common.identifiedNumber" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="PublisherIdentifiedNumber" placeholder="{{'Common.identifiedNumber' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-8">
                                <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                                    <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="CommissionTo" label="Common.date" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                                    <input type="text" #_commissionRange [owlDateTime]="CommissionTo" [owlDateTimeTrigger]="CommissionTo" nbInput fullWidth formControlName="CommissionTo" placeholder="{{'Common.date' | translate | headtitlecase}}">
                                    <owl-date-time #CommissionTo [pickerType]="'calendar'"></owl-date-time>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="Amount" label="Collaborator.Commission.label" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                                    <input type="text" nbInput fullWidth formControlName="Amount" currencyMask [options]="curencyFormat" placeholder="{{'Collaborator.Commission.label' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <!-- <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="Cycle" label="Common.cycle" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [customIcons]="objectControlIcons">
                                    <ngx-select2 formControlName="Cycle" [select2Option]="select2OptionForCycle" [data]="select2OptionForCycle.data"></ngx-select2>
                                </ngx-form-group>
                            </div> -->
                        </div>
                        <br>
                        <div class="row" *ngIf="isShowDetail(formItem)">
                            <!-- <ngx-collaboartor-commission-detail (onInit)="onListInit($event, formItem, 'statistics')" (onUpdateTotalCommission)="updateTotalCommission($event, formItem, 'statistics')" [publisher]="this.cms.getObjectId(formItem.get('Publisher').value)" [page]="this.cms.getObjectId(formItem.get('Page').value)" [moment]="formItem.get('CommissionTo').value"></ngx-collaboartor-commission-detail> -->
                            <div class="col-md-12">
                                <label class="label">{{'Doanh số và chiết khấu theo sản phẩm' | translate | headtitlecase}}</label>
                                <ngx-ag-dynamic-list #commissionReport [columnDefs]="commissionColumnDefs" [rowModelType]="'clientSide'" [rowData]="commissionData" [idKey]="'Id'" style="display: block; height: 500px"></ngx-ag-dynamic-list>
                                <!-- <div class="ag-grid-wrap" style="height: 300px; border: #ccc 1px solid; border-radius: 3px; overflow: hidden;">
                                    <ngx-ag-list formControlName="CommissionStatictis" [columnDefs]="commissionColumnDefs" [data]="commissionData" (selectChange)="onGridChange($event, formItem.get('CommissionStatictis').value)"></ngx-ag-list>
                                </div> -->
                            </div>
                            <!-- <nb-tabset>
                                <nb-tab tabTitle="{{'KPI Tuần' | translate | headtitlecase}}">
                                </nb-tab>
                                <nb-tab tabTitle="{{'KPI Tháng' | translate | headtitlecase}}">
                                    <ngx-collaboartor-award-detail (onInit)="onListInit($event, formItem, 'monthly')" [publisher]="this.cms.getObjectId(formItem.get('Publisher').value)" [page]="this.cms.getObjectId(formItem.get('Page').value)" [moment]="formItem.get('CommissionTo').value" [awardCycle]="'MONTHLY'"></ngx-collaboartor-award-detail>
                                </nb-tab>
                                <nb-tab tabTitle="{{'KPI Quý' | translate | headtitlecase}}">
                                    <ngx-collaboartor-award-detail (onInit)="onListInit($event, formItem, 'quarterly')" [publisher]="this.cms.getObjectId(formItem.get('Publisher').value)" [page]="this.cms.getObjectId(formItem.get('Page').value)" [moment]="formItem.get('CommissionTo').value" [awardCycle]="'QUARTERLY'"></ngx-collaboartor-award-detail>
                                </nb-tab>
                                <nb-tab tabTitle="{{'KPI Năm' | translate | headtitlecase}}">
                                    <ngx-collaboartor-award-detail (onInit)="onListInit($event, formItem, 'yearly')" [publisher]="this.cms.getObjectId(formItem.get('Publisher').value)" [page]="this.cms.getObjectId(formItem.get('Page').value)" [moment]="formItem.get('CommissionTo').value" [awardCycle]="'YEARLY'"></ngx-collaboartor-award-detail>
                                </nb-tab>
                            </nb-tabset> -->
                        </div>
                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton hero status="primary" (click)="preview(formItem)" [disabled]="!form.valid || isProcessing">
                            <nb-icon on b-icon pack="eva" icon="printer"></nb-icon>{{'Common.preview' | translate | headtitlecase}}
                        </button>
                        <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">Lưu & Đóng</button>
                        <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>